
import { defineComponent, SetupContext, ref, computed } from 'vue'
import { useStore } from '@/utils/composables'
import { Alert, closeSwal, toast } from '@/dependencies/sweet-alert'
import { createClientCpt } from '@/service/client.http'
import { ICPT } from '@server/api/models/fe/shared'

export default defineComponent({
	name: 'myndshft-upload',
	props: {
		title: String,
		clientId: String,
		isDisabled: {
			type: Boolean,
			default: false,
		},
	},
	setup(props, context: SetupContext) {
		const store = useStore()
		const clientProcedures = computed<ICPT[]>(
			() => store.state.client.procedures.procedures
		)
		const dragging = ref(false)
		const uploading = ref(false)

		// Unique id for input and label
		const inputId = Math.random().toString(36).substr(2, 10)

		const createDuplicatesMessage = (duplicates: string[]) => {
			const maxLines = 3
			const sample = duplicates.slice(0, maxLines)

			let message = ''
			if (duplicates.length > maxLines) {
				// Example #33, #444, #890, and 2 more
				message = sample
					.join(', ')
					.concat(`, and ${duplicates.length - maxLines} more`)
			} else if (sample.length < maxLines) {
				// Example #33 and #444
				message = sample.join(' and ')
			} else {
				// Example #33, #444, and #890
				sample[maxLines - 1] = 'and '.concat(sample[maxLines - 1])
				message = sample.join(', ')
			}

			return `Duplicate row(s) found at line(s): ${message}`
		}

		const duplicatesCheck = (payload: string) => {
			// Offset includes current line number and header
			const rowNumberOffset = 2
			const storedRows: string[] = []
			const duplicates: string[] = []
			const rows = payload.split(/\r\n|\n/)
			rows.shift()

			for (const row of rows) {
				if (storedRows.includes(row)) {
					duplicates.push(`#${storedRows.length + rowNumberOffset}`)
				}
				storedRows.push(row)
			}

			if (duplicates.length) {
				const title = createDuplicatesMessage(duplicates)
				toast({
					type: Alert.ERROR,
					title,
					timer: 20000,
				})
				return false
			}

			return true
		}

		const emptyRowCheck = (payload: string) => {
			const rows = payload.split(/\r\n|\n/)
			rows.shift()

			const emptyRow = rows.includes('')

			if (emptyRow) {
				toast({
					type: Alert.ERROR,
					title: `This upload contains one or more empty rows`,
					timer: 20000,
				})

				return false
			}

			return true
		}

		const headerCheck = (payload: string) => {
			const header = 'cpt_code,pricing_reference,val_type,val,payer_id'
			const rows = payload.split(/\r\n|\n/)

			if (rows && rows[0] && header === rows[0]) {
				return true
			}

			toast({
				type: Alert.ERROR,
				title: `Header is incorrect. Ensure the header is ${header}\n and has no white space`,
				timer: 20000,
			})

			return false
		}

		const csvFileCheck = (file: any) => {
			if (!file || !file.type || file.type !== 'text/csv') {
				toast({
					type: Alert.ERROR,
					title: `Unexpected file or file type. Ensure you are uploading a .csv file.`,
					timer: 20000,
				})

				return false
			}

			return true
		}

		const validated = (payload: string) => {
			if (headerCheck(payload)) {
				if (emptyRowCheck(payload)) {
					return duplicatesCheck(payload)
				}
			}

			return false
		}

		const uploadFile = async (event: any) => {
			if (!props.isDisabled) {
				const files = event.target.files || event.dataTransfer.files
				const id = props.clientId
				const textFormData: any = (await files[0].text()) as any

				closeSwal()

				if (csvFileCheck(files[0]) && validated(textFormData)) {
					dragging.value = true
					uploading.value = true

					const payload: any = new FormData()
					payload.append('file', (await files[0]) as any)

					const timeout = setTimeout(() => {
						// Emiting an interium message that saving is in progress
						context.emit('inprogress')
					}, 5000)

					context.emit('uploading-csv', true)
					store
						.dispatch('client/updatePricing', { id, payload })
						.then(() => {
							context.emit('success')
						})
						.catch(error => {
							context.emit('error', error)
						})
						.finally(() => {
							uploading.value = false
							dragging.value = false
							context.emit('uploading-csv', false)
							clearTimeout(timeout)
						})
				} else {
					dragging.value = false
					uploading.value = false
				}
			}
		}
		const clearFile = (event: any) => {
			event.target.value = null
		}
		return {
			inputId,
			dragging,
			uploading,
			clearFile,
			uploadFile,
		}
	},
})
